import { useIonLoading, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { supabase } from '../../supabase/supabaseClient';
import { definitions } from '../../types/supabase';
import { singletonHook } from 'react-singleton-hook';

export const useFood = () => {
  const user = supabase.auth.user();
  const [showToast] = useIonToast();
  const [savedFoods, setSavedFoods] = useState<definitions['food'][] | null>(
    null
  );

  const handleTransformResponse = (
    foodsResponse: { food: definitions['food'] }[]
  ) => {
    return foodsResponse.map((res) => res.food);
  };

  const sortFoodsByRanking = (foods: definitions['food'][]) => {
    if (foods.length > 1) return foods.sort((a, b) => a.ranking! - b.ranking!);
    else return foods;
  };

  const getSavedFoods = async () => {
    try {
      if (user) {
        const { data: savedFoodsResponse, error } = await supabase
          .from('saved-foods')
          .select(
            `
            food (
              *
            )`
          )
          .eq('user_id', user!.id);
        if (savedFoodsResponse) {
          //These are pretty expensive calculations, I wonder if we can leverage memoization
          const transformedResponse =
            handleTransformResponse(savedFoodsResponse);
          const sortedSavedFoods = sortFoodsByRanking(transformedResponse);
          setSavedFoods(sortedSavedFoods);
          return sortedSavedFoods;
        }
      }
    } catch (e: any) {
      showToast({
        message: e.error_description || e.message,
        duration: 5000,
      });
    }
  };

  const saveFood = async (id: number) => {
    try {
      if (user) {
        let { data, error, status } = await supabase
          .from<definitions['saved-foods']>('saved-foods')
          .insert([{ food_id: id, user_id: user.id }]);
        return status;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteSavedFood = async (id: number) => {
    try {
      if (user) {
        let { data: savedFood, error } = await supabase
          .from<definitions['saved-foods']>('saved-foods')
          .delete()
          .match({ user_id: user.id, food_id: id });
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSavedFoods();
  }, [user]);

  return {
    getSavedFoods,
    saveFood,
    deleteSavedFood,
    savedFoods,
  };
};
