import classNames from 'classnames';

export interface Tab {
  name: string;
  icon: any;
  index: number;
}

interface TabsProps {
  tabs: Tab[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}
const Tabs = ({ tabs, activeIndex, setActiveIndex }: TabsProps) => {
  return (
    <div className="w-full">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex justify-around" aria-label="Tabs">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className={classNames(
                tab.index === activeIndex
                  ? 'border-sky-600 text-sky-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm w-full justify-center'
              )}
              aria-current={tab.index === activeIndex ? 'page' : undefined}
              onClick={() => setActiveIndex(tab.index)}
            >
              <tab.icon
                className={classNames(
                  tab.index === activeIndex
                    ? 'text-sky-600'
                    : 'text-gray-400 group-hover:text-gray-500',
                  '-ml-0.5 mr-2 h-5 w-5'
                )}
                aria-hidden="true"
              />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
