import { IonIcon, IonItemDivider } from '@ionic/react';
import './FoodListDivider.css';
import { ribbonOutline } from 'ionicons/icons';

interface FoodListDividerProps {
  tier: '1' | '2' | '3';
}

const FoodListDivider = ({ tier }: FoodListDividerProps) => {
  const tierText = {
    '1': "This is why we're all here, eat these!",
    '2': 'If you have room, eat these',
    '3': "Some of the classics that don't quit",
  };

  const tierClass = {
    '1': 'tier-one',
    '2': 'tier-two',
    '3': 'tier-three',
  };

  return (
    <IonItemDivider class="divider">
      <div className={`bubble ${tierClass[tier]}`}>
        <IonIcon icon={ribbonOutline}></IonIcon>
        <span>{tierText[tier]}</span>
      </div>
    </IonItemDivider>
  );
};

export default FoodListDivider;
