import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { person, list, map, ellipsisHorizontal } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';
import { useRef, useState } from 'react';
import { Redirect, Route } from 'react-router';
import About from '../About';
import FoodList from '../FoodList';
import Profile from '../Profile';
import NewMap from '../../shared/components/NewMap';

import { useLoadScript } from '@react-google-maps/api';

const AppView = () => {
  const [menuOffsetActive, setMenuOffsetActive] = useState<boolean>(true);
  const tabRef = useRef<HTMLIonTabsElement>(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  // TODO: find ways to type Ionic events, they use custom events and that is problematic
  const senseTabChange = (event: any) => {
    return setMenuOffsetActive(event.detail.tab === 'map');
  };

  return (
    <IonApp>
      <IonReactRouter>
        {/* TODO: reimplement nav bar sliding up on nav to map */}
        {/* ref={tabRef} onIonTabsDidChange={(e) => senseTabChange(e)} */}
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/food-list">
              <FoodList />
            </Route>
            <Route exact path="/map">
              {isLoaded && <NewMap />}
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/">
              <Redirect to="/food-list" />
            </Route>
          </IonRouterOutlet>
          {/* className={menuOffsetActive ? 'offset' : ''} */}
          <IonTabBar slot="bottom">
            <IonTabButton tab="food-list" href="/food-list">
              <IonIcon icon={list} />
              <IonLabel>Food list</IonLabel>
            </IonTabButton>
            <IonTabButton tab="map" href="/map">
              <IonIcon icon={map} />
              <IonLabel>Map</IonLabel>
            </IonTabButton>
            <IonTabButton tab="profile" href="/profile">
              <IonIcon icon={person} />
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
            <IonTabButton tab="about" href="/about">
              <IonIcon icon={ellipsisHorizontal} />
              <IonLabel>About</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};
export default AppView;
