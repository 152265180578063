import { useIonLoading, useIonToast } from '@ionic/react';
import { supabase } from '../../supabase/supabaseClient';
const useProfile = () => {
  const user = supabase.auth.user();
  const [showToast] = useIonToast();
  const [showLoading, hideLoading] = useIonLoading();

  const getProfile = async () => {
    if (supabase.auth.user() === null) return;
    await showLoading();
    try {
      const user = supabase.auth.user();
      let { data, error, status } = await supabase
        .from('profiles')
        .select(`username, website, avatar_url`)
        .eq('id', user!.id)
        .single();

      if (error && status !== 406) {
        throw error;
      }
      if (data) {
        return data;
      }
    } catch (error: any) {
      showToast({ message: error.message, duration: 5000 });
    } finally {
      await hideLoading();
    }
  };

  const updateProfile = async (profile: { username: string }) => {
    await showLoading();
    try {
      const updates = {
        id: user!.id,
        ...profile,
        updated_at: new Date(),
      };

      let { error } = await supabase.from('profiles').upsert(updates, {
        returning: 'minimal', // Don't return the value after inserting
      });

      if (error) {
        throw error;
      }
    } catch (error: any) {
      showToast({ message: error.message, duration: 5000 });
    } finally {
      await hideLoading();
      showToast({
        message: 'Successfully updated!',
        duration: 5000,
        color: 'success',
        cssClass: 'font-bold text-md',
        mode: 'ios',
        position: 'top',
      });
    }
  };
  return {
    updateProfile,
    getProfile,
  };
};

export default useProfile;
