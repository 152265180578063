import { isPlatform, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/floating-nav.css';

import { Session } from '@supabase/supabase-js';
import { useState, useEffect } from 'react';
import { supabase } from './supabase/supabaseClient';
import { useFood } from './shared/hooks/useFood';
import AppView from './pages/AppView/AppView';
import MobilePreviewFrame from './pages/MobilePreviewFrame/MobilePreviewFrame';
import { useFullscreen } from './shared/hooks/useFullscreen';

setupIonicReact({
  mode: 'ios',  
});

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);

  const { getSavedFoods } = useFood();
  const { fullscreenActive, setFullscreenActive } = useFullscreen();

  useEffect(() => {
    setSession(supabase.auth.session());
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    getSavedFoods();
  }, [session]);

  return (
    <>
      {fullscreenActive !== null ? (
        !fullscreenActive ? (
          <MobilePreviewFrame
            toggleFullscreen={() => setFullscreenActive(true)}
          >
            <AppView></AppView>
          </MobilePreviewFrame>
        ) : (
          <AppView />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
