import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonGrid,
  IonButton,
  IonButtons,
  IonIcon,
  IonBackButton,
} from '@ionic/react';
import { ReactNode, forwardRef } from 'react';
import { close } from 'ionicons/icons';

const PageWrapper: React.FC<{
  title: string;
  children: ReactNode;
  onDismiss?: () => void;
  dismissType?: string;
  ref?: any;
  onAction?: () => void;
  actionIcon?: string;
  contentClass?: string;
}> = forwardRef(
  (
    {
      title,
      children,
      onDismiss,
      dismissType,
      onAction,
      actionIcon,
      contentClass,
    },
    ref
  ) => (
    <IonPage ref={ref}>
      <IonHeader translucent>
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            {onDismiss && dismissType === 'close' ? (
              <IonButton onClick={onDismiss}>
                <IonIcon icon={close} />
              </IonButton>
            ) : (
              dismissType === 'back' && (
                <IonButtons slot="start">
                  <IonBackButton />
                </IonButtons>
              )
            )}
          </IonButtons>
          <IonButtons slot="end">
            {onAction && (
              <IonButton onClick={onAction}>
                <IonIcon icon={actionIcon} />
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={contentClass} fullscreen>
        <IonGrid fixed>{children}</IonGrid>
      </IonContent>
    </IonPage>
  )
);

export default PageWrapper;
