/*
 * This function properly parses out any symbols and appends the country code.
 * However, Supabase Auth throws an error anytime the user inputs a country code.
 * For now, we only accommodate US numbers without the +1 which we add manually.
 */
export const preparePhoneNumber = (phone: string) => {
  if (phone.startsWith('+')) {
    //Removes all symbols expect for +
    return phone.replace(/[^\d\+]/g, '');
  } else {
    return `+1${phone.replace(/[^\d]/g, '')}`;
  }
};
