import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useFood } from '../../hooks/useFood';
import { findIcon } from '../../../shared/categories';
import PageWrapper from '../PageWrapper';
import { definitions } from '../../../types/supabase';
import { IonModal, IonSearchbar } from '@ionic/react';
import FoodDetail from '../FoodDetail';
import Profile from '../../../pages/Profile';
import { motion } from 'framer-motion';
import NewBadge from '../NewBadge';
import { useSingletonFood } from '../../hooks/useSingletonFood';

type LatLngLiteral = google.maps.LatLngLiteral;
type MapOptions = google.maps.MapOptions;
const stateFairGrandStand = {
  lat: 44.98009,
  lng: -93.17187,
};

const NewMap = () => {
  //Map config
  const mapRef = useRef<GoogleMap>();
  const center = useMemo<LatLngLiteral>(() => stateFairGrandStand, []);
  const options = useMemo<MapOptions>(
    () => ({
      mapId: 'd8f394f16d9f4a90', //this mapId comes from the Google Maps Platform under Map Management
      disableDefaultUI: true,
    }),
    [],
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  //Food config
  const { savedFoods, getSavedFoods } = useFood();
  const foods = useSingletonFood();

  //refs
  const page = useRef(null);

  //state
  const [selectedMarker, setSelectedMarker] = useState<
    definitions['food'] | null
  >(null);
  const [zoom, setZoom] = useState(16.2);

  //methods
  const markerClick = (food: definitions['food']) => {
    setSelectedMarker(food);
    if (mapRef.current) {
      mapRef.current.panTo({
        lat: parseFloat(`${food.latitude}`),
        lng: parseFloat(`${food.longitude}`),
      });
      setZoom(19);
    }
    setShowModal(true);
  };

  //food modal
  const [showModal, setShowModal] = useState(false);
  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);

  const handleCloseMarkerModal = () => {
    setZoom(17);
    setShowModal(false);
    setSelectedMarker(null);
    getSavedFoods();
  };

  //login modal
  const promptLoginModal = useRef<HTMLIonModalElement>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  useEffect(() => {
    setPresentingElement(page.current);
  }, []);

  //search bar
  const [searchText, setSearchText] = useState('');
  const [searching, setSearching] = useState(false);

  const handleSearch = (e: CustomEvent) => {
    setSearching(true);
    setSearchText(e.detail.value);
  };
  const handleSelectItem = (food: definitions['food']) => {
    setSearching(false);
    setSelectedMarker(food);
    //pan and zoom map to the marker
    if (mapRef.current) {
      mapRef.current.panTo({
        lat: food.latitude as number,
        lng: food.longitude as number,
      });
      setZoom(19);
    }
    setShowModal(true);
  };

  const currentYear = new Date().getFullYear().toString();

  return (
    <PageWrapper title="Food Map" ref={page}>
      <div className="w-full bg-white/40 backdrop-blur-lg absolute top-10 z-50 shadow-lg  ">
        <IonSearchbar
          value={searchText}
          onIonChange={handleSearch}
          animated
          showCancelButton="focus"
          onIonCancel={() => setSearching(false)}
          onIonFocus={() => setSearching(true)}
        ></IonSearchbar>
        <div>
          {searching &&
            foods &&
            foods.length > 0 &&
            foods
              .filter((food: definitions['food']) => {
                if (
                  food.title
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  food.vendor
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  food.description
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase())
                ) {
                  return food;
                }
              })
              .slice(0, 3)
              .map((food, index) => (
                <motion.div
                  key={index}
                  layout
                  className="ml-4 flex mb-4 items-center gap-x-4 z-50"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={() => handleSelectItem(food)}
                >
                  <div className="relative">
                    <motion.img
                      src={
                        food.image_url || '/assets/state-fair-placeholder.jpg'
                      }
                      alt={food.title}
                      className="w-12 h-12 rounded-md"
                    />
                    {food.year_added === currentYear && (
                      <div className="absolute -top-2 -right-6">
                        <NewBadge />
                      </div>
                    )}
                  </div>
                  <p className="font-medium text-lg w-[60%] text-ellipsis overflow-hidden">
                    {food.title}
                  </p>
                </motion.div>
              ))}
        </div>
      </div>
      {foods && foods.length > 0 ? (
        <GoogleMap
          zoom={zoom}
          center={center}
          mapContainerClassName="w-full h-screen max-h-[780px] z-10"
          options={options}
          onLoad={onLoad}
        >
          {foods &&
            foods.length > 0 &&
            foods.map((food) => (
              <Marker
                key={`${food.title}+${food.latitude}`}
                position={{
                  lat: parseFloat(`${food.latitude}`),
                  lng: parseFloat(`${food.longitude}`),
                }}
                icon={findIcon(food.category as string) as string}
                title={food.title}
                onClick={() => {
                  markerClick(food);
                }}
              />
            ))}
        </GoogleMap>
      ) : (
        <h1 className="font-bold text-black">Loading</h1>
      )}
      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.7}
        breakpoints={[0, 0.7, 0.9]}
        backdropBreakpoint={0}
        onDidDismiss={() => {
          handleCloseMarkerModal();
        }}
        backdropDismiss={true}
        handleBehavior="cycle"
      >
        <FoodDetail
          food={selectedMarker}
          handleDismiss={() => handleCloseMarkerModal()}
          handleShowLoginModal={() => setShowLoginModal(true)}
          savedFoods={savedFoods}
        />
      </IonModal>
      <IonModal
        isOpen={showLoginModal}
        ref={promptLoginModal}
        trigger="open-modal"
        presentingElement={presentingElement!}
      >
        <Profile
          onDismiss={() => setShowLoginModal(false)}
          dismissType="close"
        />
      </IonModal>
    </PageWrapper>
  );
};

export default NewMap;
