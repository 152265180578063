export enum Categories {
  Any = 'any',
  Breakfast = 'breakfast',
  Dessert = 'dessert',
  Meat = 'meat',
  Beverage = 'beverage',
  Sandwich = 'sandwich',
  Main = 'main',
}

export const findIcon = (category: string) => {
  switch (category) {
    case 'any':
      return '/assets/categories/any.svg';
    case 'beverage':
      return '/assets/categories/beverage.svg';
    case 'breakfast':
      return '/assets/categories/breakfast.svg';
    case 'dessert':
      return '/assets/categories/dessert.svg';
    case 'main':
      return '/assets/categories/main.svg';
    case 'meat':
      return '/assets/categories/meat2.svg';
    case 'sandwich':
      return '/assets/categories/sandwich.svg';
    default:
      return '/assets/categories/any.svg';
  }
};
