import { IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import { definitions } from '../../../types/supabase';
import NewBadge from '../NewBadge';

interface FoodItemProps {
  food: definitions['food'];
  handleSelectItem: (food: definitions['food']) => void;
}
const currentYear = new Date().getFullYear().toString();

const FoodItem = ({ handleSelectItem, food }: FoodItemProps) => {
  return (
    <IonItem onClick={() => handleSelectItem(food)}>
      <IonThumbnail slot="start">
        <div className="relative">
          <img
            src={food.image_url || '/assets/state-fair-placeholder.jpg'}
            alt={food.title}
            className="w-12 h-12 rounded-md object-cover"
          />
          {food.year_added === currentYear && (
            <div className="absolute -bottom-2 -right-2">
              <NewBadge />
            </div>
          )}
        </div>
      </IonThumbnail>
      <IonLabel>
        <h2>
          <span className="font-semibold">{food.ranking}</span>. {food.title}
        </h2>
        <p>{food.description}</p>
      </IonLabel>
    </IonItem>
  );
};

export default FoodItem;
