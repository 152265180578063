import { IonContent, IonIcon } from '@ionic/react';
import { location } from 'ionicons/icons';
import { motion } from 'framer-motion';
import { HeartIcon as HeartIconOutline } from '@heroicons/react/outline';
import { HeartIcon as HeartIconFilled } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/solid';
import { definitions } from '../../../types/supabase';
import NewBadge from '../NewBadge';
import { useEffect, useState } from 'react';
import { useFood } from '../../hooks/useFood';
import { supabase } from '../../../supabase/supabaseClient';

const FoodDetail = ({
  food,
  savedFoods,
  handleDismiss,
  handleShowLoginModal,
}: {
  food?: definitions['food'] | null;
  savedFoods?: definitions['food'][] | null;
  handleDismiss: () => void;
  handleShowLoginModal: () => void;
}) => {
  const user = supabase.auth.user();
  const [favorited, setFavorited] = useState(false);
  const { saveFood, deleteSavedFood } = useFood();

  const handleSaveFood = async () => {
    if (user) {
      if (food) {
        const resStatus = await saveFood(food.id);
        if (resStatus === 201) setFavorited(true);
      }
    } else {
      handleShowLoginModal();
      handleDismiss();
    }
  };

  const handleDeleteSavedFood = () => {
    if (food) deleteSavedFood(food.id);
    setFavorited(false);
  };

  const checkIfSaved = () => {
    if (food && savedFoods) {
      const isSaved = savedFoods?.some((foodItem: any) => {
        return foodItem.id === food?.id;
      });
      if (isSaved) setFavorited(isSaved);
    }
  };

  useEffect(() => {
    if (user) {
      checkIfSaved();
    }
  }, [food]);

  const currentYear = new Date().getFullYear().toString();

  return (
    <IonContent>
      {food && (
        <>
          <div
            className="bg-cover bg-top w-full h-[300px] relative flex items-center"
            style={{
              backgroundImage: `url(${
                food.image_url || '/assets/state-fair-placeholder.jpg'
              })`,
            }}
          >
            <motion.div
              className="absolute top-4 right-4 bg-black/20 p-1 rounded-lg"
              whileTap={{ scale: 0.95 }}
              onClick={() => handleDismiss()}
            >
              <XIcon className="h-6 h6 text-white" />
            </motion.div>
          </div>
          <div className="flex flex-col px-4 py-2 gap-y-1">
            <div className="flex items-start gap-x-2 mt-2">
              <h1 className="font-bold text-2xl">{food.title}</h1>
              {food.year_added === currentYear && <NewBadge />}
              {!favorited ? (
                <HeartIconOutline
                  className="h-6 w-6 mt-1"
                  onClick={() => handleSaveFood()}
                />
              ) : (
                <motion.div
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, scale: 0.5, y: 10 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                >
                  <HeartIconFilled
                    className="h-6 w-6 text-red-500"
                    onClick={() => handleDeleteSavedFood()}
                  />
                </motion.div>
              )}
            </div>
            <div className="flex items-center gap-x-2 text-gray-600">
              <p>{food.vendor}</p>
              <IonIcon icon={location}></IonIcon>
              <p className="text-gray-400">{food.location_code}</p>
            </div>
            {food.description && (
              <div className="text-sm text-gray-400 ">
                <p className="text-ellipsis overflow-hidden h-18">
                  {food.description} The {food.title} was added in{' '}
                  {food.year_added}.
                </p>
              </div>
            )}
            {food.directions_url && (
              <a href={food.directions_url} className="w-full">
                <motion.button
                  whileTap={{ scale: 0.95 }}
                  className="bg-sky-500 py-2 font-semibold text-white rounded-md mt-4 hover:bg-sky-700 focus:bg-sky-700 transition-colors w-full"
                >
                  Get directions
                </motion.button>
              </a>
            )}
          </div>
        </>
      )}
    </IonContent>
  );
};

export default FoodDetail;
