import { useEffect, useState } from 'react';
import { supabase } from '../../supabase/supabaseClient';
import { definitions } from '../../types/supabase';
import { singletonHook } from 'react-singleton-hook';
import { useIonLoading, useIonToast } from '@ionic/react';

const init = null;
const useSingletonFoodImpl = () => {
  //state
  const [foods, setFoods] = useState<definitions['food'][] | null>(null);
  //hooks
  const [showToast] = useIonToast();
  const [showLoading, hideLoading] = useIonLoading();
  //methods
  const sortFoodsByRanking = (foods: definitions['food'][]) => {
    if (foods.length > 1) return foods.sort((a, b) => a.ranking! - b.ranking!);
    else return foods;
  };
  //get food
  const getAllFood = async () => {
    showLoading();
    try {
      let { data: food, error } = await supabase
        .from<definitions['food']>('food')
        .select('*');
      if (food) {
        const sortedFood = sortFoodsByRanking(food);
        setFoods(sortedFood);
        return sortedFood;
      } else {
        showToast({
          message: error?.message,
          duration: 5000,
        });
      }
    } catch (e: any) {
      console.error(e);
      showToast({
        message: e.error_description || e.message,
        duration: 5000,
      });
    } finally {
      hideLoading();
    }
  };

  //effects
  useEffect(() => {
    getAllFood();
  }, []);
  return foods;
};

export const useSingletonFood = singletonHook(init, useSingletonFoodImpl);
