import { motion } from 'framer-motion';
import { IonIcon } from '@ionic/react';
import classNames from 'classnames';

// This button currently doesn't support href, add if necessary

//TODO: Add loading and disabled states

interface ButtonProps {
  onClick: () => void;
  icon?: any; //The name of the ionic icon
  text: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}
const Button = ({
  onClick,
  icon,
  text,
  type = 'button',
  disabled,
}: ButtonProps) => {
  return (
    <motion.button
      type={type}
      whileTap={{ scale: 0.95 }}
      className={classNames(
        'py-2 font-semibold text-white rounded-md mt-4 bg-sky-600 hover:bg-sky-700 focus:bg-sky-700 transition-colors w-full flex items-center justify-center gap-x-2',
        { disabled: disabled }
      )}
      onClick={() => onClick()}
    >
      {icon && <IonIcon icon={icon} className="w-5 h-5" />}
      {text}
    </motion.button>
  );
};

export default Button;
