import { isPlatform } from '@ionic/react';
import { useEffect, useState } from 'react';

export const useFullscreen = () => {
  const [isDesktopActive, setIsDesktopActive] = useState<boolean | null>(null);
  const [fullscreenActive, setFullscreenActive] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    const platformIsDesktop = isPlatform('desktop');
    const viewIsWide = window.innerWidth > 768;

    setIsDesktopActive(platformIsDesktop && viewIsWide);
  }, []);

  useEffect(() => {
    if (isDesktopActive !== null) {
      setFullscreenActive(!isDesktopActive);
    }
  }, [isDesktopActive]);

  return {
    fullscreenActive,
    setFullscreenActive,
  };
};
