import { useEffect, useState } from 'react';
import {
  IonInput,
  IonItem,
  IonLabel,
  useIonToast,
  useIonRouter,
  IonModal,
} from '@ionic/react';
import { supabase } from '../../supabase/supabaseClient';
import PageWrapper from '../../shared/components/PageWrapper';
import Spacer from '../../shared/components/Spacer';
import VerifyOTPCode from './VerifyOTPCode';
import Button from '../../shared/components/Button';
import useProfile from '../../shared/hooks/useProfile';
import { preparePhoneNumber } from '../../shared/helpers';
import { LockClosedIcon, HeartIcon } from '@heroicons/react/solid';

interface ProfileProps {
  onDismiss?: () => void;
  dismissType?: string;
}

function Profile({ onDismiss, dismissType }: ProfileProps) {
  const user = supabase.auth.user();
  //State
  const [showToast] = useIonToast();
  const [session, setSession] = useState(() => supabase.auth.session());
  const { updateProfile, getProfile } = useProfile();
  const router = useIonRouter();
  const [profile, setProfile] = useState({
    username: '',
  });
  const [phone, setPhone] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  //Methods
  const signOut = async () => {
    await supabase.auth.signOut();
    setProfile({ username: '' });
    router.push('/profile', 'forward', 'replace');
  };

  const signInWithPhone = async () => {
    if (phone) {
      const phoneNumber = preparePhoneNumber(phone);
      const { error } = await supabase.auth.signIn({ phone: phoneNumber });
      if (error) {
        console.error(error);
        showToast({ message: error.message, duration: 5000 });
      }
    }
  };

  const handleLoginWithPhone = () => {
    setShowModal(true);
    signInWithPhone();
  };

  const handleCloseOTPModal = () => {
    setPhone(null);
    setShowModal(false);
  };

  const handleUpdateSession = (session: any) => {
    setSession(session);
  };

  const handleGetProfile = async () => {
    if (user) {
      const profile = await getProfile();
      setProfile({ username: profile.username });
    }
  };

  //Effects
  useEffect(() => {
    handleGetProfile();
  }, [session]);

  return (
    <PageWrapper
      title="Profile"
      onDismiss={onDismiss}
      dismissType={dismissType}
    >
      <main className="mt-4 px-4">
        {supabase.auth.user() ? (
          <>
            <div>
              <div className="w-full bg-green-200 text-green-700 py-2 rounded-lg px-4 font-semibold flex items-center mb-4">
                <LockClosedIcon className="mr-2 w-4 h-4" />
                <p>You're logged in with {session?.user?.phone}</p>
              </div>
              <div className="px-4 bg-gray-100 rounded-lg py-4">
                <p>
                  You can now <strong>favorite foods</strong>! Click the{' '}
                  <HeartIcon className="h-5 w-5 text-red-500 inline mb-1" />{' '}
                  icon when looking at a food item and then find your favorites
                  on the Food List. Now close this page and start eating!
                </p>
              </div>
              {/* 
              <IonItem>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                  type="text"
                  name="username"
                  value={profile.username}
                  onIonChange={(e) =>
                    setProfile({ ...profile, username: e.detail.value ?? '' })
                  }
                ></IonInput>
              </IonItem>
              <div className="ion-text-center">
                <Button
                  text="Update Profile"
                  onClick={() => updateProfile(profile)}
                />
              </div> */}
            </div>
            <Spacer height="h-4" />
            <div className="ion-text-center">
              <Button text="Log out" onClick={signOut} />
            </div>
          </>
        ) : (
          <>
            <Spacer height="h-6" />
            <div className="ml-1">
              <h2 className="font-bold text-2xl">Easy sign up</h2>
              <p className="text-base text-gray-500">
                Log in to create a list of favorites
              </p>
              <Spacer height="h-4" />
              <IonItem className="ion-no-padding">
                <IonLabel className="font-semibold">Phone number:</IonLabel>
                <IonInput
                  value={phone}
                  name="phone"
                  onIonChange={(e) => setPhone(e.detail.value!)}
                  type="tel"
                  placeholder="6123334444"
                  autofocus
                ></IonInput>
              </IonItem>
              <div className="ion-text-center">
                <Button
                  onClick={() => handleLoginWithPhone()}
                  disabled={phone === null || phone.length < 10}
                  text="Text me a code"
                />
                <Spacer height="h-4" />
                <div className="text-gray-400">
                  <small>This is just to sign in, we'll never text you.</small>
                  <br />
                  <small>We only support </small>
                  <img
                    src="/assets/american-flag.png"
                    className="w-4 inline mx-1"
                  />
                  <small>US numbers at this time.</small>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.8}
        breakpoints={[0, 0.8, 0.9]}
        backdropBreakpoint={0}
        onDidDismiss={() => {
          handleCloseOTPModal();
        }}
        backdropDismiss={true}
        handleBehavior="cycle"
      >
        <VerifyOTPCode
          phone={phone as string}
          handleDismiss={() => setShowModal(false)}
          handleUpdateSession={(session) => handleUpdateSession(session)}
        />
      </IonModal>
    </PageWrapper>
  );
}

export default Profile;
