import React from 'react';
import classNames from 'classnames';

/*
 * Because Tailwind needs to see the full string for each utility class
 * we need to pass a full class name for height as props (i.e. h-8).
 */

const Spacer = ({ height }: { height: string }) => {
  return <div className={classNames('w-full', height)} />;
};

export default Spacer;
