import { shareOutline } from 'ionicons/icons';
import { motion } from 'framer-motion';
import PageWrapper from '../../shared/components/PageWrapper';
import Spacer from '../../shared/components/Spacer';
import AvatarWithText from '../../shared/components/AvatarWithText';
import { Share } from '@capacitor/share';
import { useEffect, useState } from 'react';
import Button from '../../shared/components/Button';

const About = () => {
  const [browserCanShare, setBrowserCanShare] = useState<boolean>(true);
  const handleShare = async () => {
    await Share.share({
      title: 'MN Foodie Fair Guide',
      url: 'https://app.mnfoodiefairguide.com/',
      dialogTitle: 'Share with your state fair friends',
    });
  };

  //Check if the browser supports sharing with Web Share API
  useEffect(() => {
    const checkIfBrowserCanShare = async () => {
      const { value: canShare } = await Share.canShare();
      setBrowserCanShare(canShare);
    };
    checkIfBrowserCanShare();
  }, []);

  return (
    <PageWrapper title="About">
      <main className="px-4 mt-4 mb-8">
        <div className="px-4 bg-gray-100 rounded-lg py-4">
          <img
            src="/assets/made-by-cloudburst.svg"
            className="w-full"
            alt="Made by Cloudburst"
          />
          <p className="font-medium text-gray-600 mt-2 mb-1">
            Ready to build your own app?
          </p>
          <a href="https://www.cloudburststudio.com/">
            <motion.button
              whileTap={{ scale: 0.95 }}
              className="bg-[#45B84B] py-2 font-semibold text-white rounded-md mt-4 hover:bg-green-700 focus:bg-green-700 transition-colors w-full"
            >
              Let's chat!
            </motion.button>
          </a>
        </div>
        <Spacer height="h-4" />
        <div className="px-4 bg-gray-100 rounded-lg py-4">
          <h2 className="font-bold text-xl mb-4">About this app</h2>
          <p>
            This app is a curated list of items that are classics that don't
            quit and the new items that have made the cut. Peruse the list,
            check out where they're located on the handy map so you can be
            efficient in your eating and group items together, share or don't
            share - it's all up to your stamina and stomach capacity. And if you
            try something and love it, heart it! We want to know what you
            thought was worthy of sticking on the list! More features are coming
            next year, so please enjoy this digital display of the items we're
            looking forward to most for this year's MN State Fair. There's 50
            items - I challenge you to do your best!
          </p>
        </div>
        {browserCanShare ? (
          <>
            <Button
              onClick={handleShare}
              icon={shareOutline}
              text="Share a link to this app"
            />
          </>
        ) : (
          <div className="px-4 bg-gray-100 rounded-lg py-4 mt-4">
            <h2 className="font-bold text-xl mb-4">Share the app</h2>
            <img
              src="/assets/qr-code.png"
              className="w-4/5 mx-auto"
              alt="QR Code to share the MN Fair Food Guide app"
            />
          </div>
        )}
        <Spacer height="h-4" />
        <div className="px-4 bg-gray-100 rounded-lg py-4">
          <div className="flex justify-between">
            <h2 className="font-bold text-xl mb-4">My Story</h2>
            <AvatarWithText imageSrc="/assets/allison-funny.jpg" />
          </div>
          <Spacer height="h-2" />
          <p>
            Who makes a spreadsheet of fair food items and turns it into
            something to share with the world? A Saint Paul native that's a
            recovering food scientist who loves to eat, that's who! Allison has
            been building and sharing this list with friends and family for a
            decade and finally decided to pull the trigger on making the jump
            from analog paper copy to digital. If you're the type of person to
            scour the new food items list every year or you just like to stare
            in awe at the stupid amount of fried items on a stick, this list is
            for you. Peruse, enjoy, eat, and don't forget - there's always more
            room in the dessert compartment in the stomach, choose items wisely!
          </p>
          <Spacer height="h-4" />
        </div>
        <Spacer height="h-4" />
        <div className="px-4 bg-gray-100 rounded-lg py-4">
          <div className="flex justify-between">
            <h2 id="#privacy" className="font-bold text-xl mb-4">
              Privacy
            </h2>
          </div>
          <Spacer height="h-2" />
          <p>
            This app uses Google Analytics as well as other tools and services
            to ensure an optimal experience. Additional privacy information for
            Google Analytics is available{' '}
            <a
              className="underline"
              href="https://policies.google.com/privacy?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . Opt out of analytics tracking by clicking{' '}
            <a
              href="#privacy"
              className="underline"
              onClick={() => {
                window.localStorage?.setItem('gaMNFFGoptedOut', 'true');
              }}
            >
              here
            </a>
            .
          </p>
          <Spacer height="h-4" />
        </div>
        <Spacer height="h-4" />
      </main>
    </PageWrapper>
  );
};

export default About;
