interface AvatarWithTextProps {
  imageSrc: string;
}

const AvatarWithText = ({ imageSrc }: AvatarWithTextProps) => {
  return (
    <a href="#" className="flex-shrink-0 group block">
      <div className="flex items-center">
        <div className="mr-3">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            Allison Hohn
          </p>
        </div>
        <div>
          <img
            className="inline-block h-12 w-12 rounded-full"
            src={imageSrc}
            alt=""
          />
        </div>
      </div>
    </a>
  );
};

export default AvatarWithText;
