import {
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  useIonToast,
} from '@ionic/react';
import { useState } from 'react';
import { supabase } from '../../../supabase/supabaseClient';
import { Session } from '@supabase/gotrue-js/src/lib/types';
import Button from '../../../shared/components/Button';
import { preparePhoneNumber } from '../../../shared/helpers';

interface VerifyOTPCodeProps {
  phone: string | null;
  handleDismiss: () => void;
  handleUpdateSession: (session: Session) => void;
}

//TODO: Handle if input doesn't match code
const VerifyOTPCode = ({
  phone,
  handleDismiss,
  handleUpdateSession,
}: VerifyOTPCodeProps) => {
  const [token, setToken] = useState<string | null | undefined>('');
  const [showToast] = useIonToast();

  const handleSubmit = async () => {
    if (phone && token) {
      const phoneNumber = preparePhoneNumber(phone);
      const { session, error } = await supabase.auth.verifyOTP({
        phone: phoneNumber,
        token,
      });
      if (session) {
        handleUpdateSession(session);
        handleDismiss();
      }
      if (error) {
        showToast({ message: error.message, duration: 5000 });
        console.error(error);
      }
    }
  };
  return (
    <IonContent>
      <div className="px-6">
        <p className="text-2xl font-bold text-black mt-4">Sign in</p>
        <IonItem className="ion-no-padding">
          <IonLabel position="stacked">Your one-time code</IonLabel>
          <IonInput
            type="tel"
            placeholder="123456"
            onIonChange={(e) => setToken(e.detail.value)}
            autocomplete="one-time-code"
          />
        </IonItem>
        <Button
          text="Verify"
          onClick={() => handleSubmit()}
          disabled={token === null || token?.length !== 6}
        />
      </div>
    </IonContent>
  );
};

export default VerifyOTPCode;
