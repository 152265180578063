import classNames from 'classnames';

interface CategoryBadgeProps {
  title: string;
  active: boolean;
  onClick: () => void;
}
const CategoryBadge = ({ title, active, onClick }: CategoryBadgeProps) => {
  return (
    <div
      onClick={() => onClick()}
      className={`
          border rounded-full text-sm px-3 py-1 w-max font-medium ${
            active
              ? 'bg-sky-200 text-sky-700 border-sky-500'
              : 'bg-slate-200 text-slate-700'
          } `}
    >
      {title}
    </div>
  );
};

export default CategoryBadge;
