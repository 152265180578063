import {
  IonList,
  IonModal,
  IonSearchbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { refresh } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import FoodDetail from '../../shared/components/FoodDetail';
import PageWrapper from '../../shared/components/PageWrapper';
import { useFood } from '../../shared/hooks/useFood';
import { useSingletonFood } from '../../shared/hooks/useSingletonFood';
import { definitions } from '../../types/supabase';
import { supabase } from '../../supabase/supabaseClient';
import Profile from '../Profile';
import FoodItem from './../../shared/components/FoodItem';
import Tabs from '../../shared/components/Tabs';
import { Tab as TabType } from '../../shared/components/Tabs/Tabs';
import { HeartIcon, ViewListIcon } from '@heroicons/react/solid';
import Spacer from '../../shared/components/Spacer';
import Button from '../../shared/components/Button';
import './FoodList.css';
import CategoryBadge from '../../shared/components/CategoryBadge';
import FoodListDivider from './Components/FoodListDivider';
import { Categories } from '../../shared/categories';

type Food = definitions['food'];

const FoodList = () => {
  //State and refs
  const [searchText, setSearchText] = useState('');
  const [selectedMarker, setSelectedMarker] = useState<
    definitions['food'] | null
  >(null);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);
  //State for which tab is active
  const [activeIndex, setActiveIndex] = useState(0);

  const user = supabase.auth.user();

  const { savedFoods, getSavedFoods } = useFood();
  const foods = useSingletonFood();
  const page = useRef(null);
  const promptLoginModal = useRef<HTMLIonModalElement>(null);

  const tabs: TabType[] = [
    {
      name: 'All food',
      index: 0,
      icon: ViewListIcon,
    },
    {
      name: 'Favorites',
      index: 1,
      icon: HeartIcon,
    },
  ];

  //Methods
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMarker(null);
    getSavedFoods();
  };

  const handleSelectItem = (item: definitions['food']) => {
    setSelectedMarker(item);
    setShowModal(true);
  };

  const matchesSearch = (foodList: definitions['food'][]) => {
    return foodList.filter((food) => {
      if (
        food.title?.toLowerCase().includes(searchText.toLowerCase()) ||
        food.vendor?.toLowerCase().includes(searchText.toLowerCase()) ||
        food.description?.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return food;
      }
    });
  };
  const [selectedCategory, setSelectedCategory] = useState<string>('all');

  // Generates the FoodItems per tier
  const generateFoodTierItems = (foodsInput: Food[]) => {
    if (foodsInput.length > 0) {
      return foodsInput.map((food, index) => {
        return (
          <FoodItem
            handleSelectItem={handleSelectItem}
            food={food}
            key={index}
          />
        );
      });
    }
  };

  const generateFoodTier = (tier: '1' | '2' | '3') => {
    if (foods) {
      const filteredFoods = foods
        .filter((food) => food.tier === tier)
        .filter((food) => filterBySearch(food))
        .filter((food) => filterByCategory(food));

      if (filteredFoods.length > 0) {
        return (
          <>
            <FoodListDivider tier={tier}></FoodListDivider>
            {generateFoodTierItems(filteredFoods)}
          </>
        );
      }
    }
  };

  const filterBySearch = (food: Food) => {
    // Search filters
    const searchContainsTitle = food.title
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
    const searchContainsVendor = food.vendor
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
    const searchContainsDescription = food.description
      ?.toLowerCase()
      .includes(searchText.toLowerCase());
    const foundInSearch =
      searchContainsTitle || searchContainsVendor || searchContainsDescription;

    return foundInSearch;
  };

  const filterByCategory = (food: Food) => {
    return selectedCategory === 'all'
      ? true
      : food.category === selectedCategory;
  };

  const handleRedirectToProfile = () => {
    setShowLoginModal(true);
  };

  // Effects
  useEffect(() => {
    // getAllFood();
    // getSavedFoods();
    setPresentingElement(page.current);
  }, []);

  useIonViewWillEnter(() => {
    // getSavedFoods();
  });

  return (
    <PageWrapper
      title="Food List"
      ref={page}
      // Super janky workaround to refresh favorites
      onAction={() => window.location.reload()}
      actionIcon={refresh}
    >
      <IonSearchbar
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value!)}
      ></IonSearchbar>
      <Tabs
        tabs={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <Spacer height="h-4" />
      {activeIndex === 0 && (
        <>
          <section className="px-4 mb-1 flex gap-x-2 overflow-x-scroll">
            <CategoryBadge
              title="All"
              active={selectedCategory === 'all'}
              onClick={() => setSelectedCategory('all')}
            />
            <CategoryBadge
              title="Sandwich"
              active={selectedCategory === Categories.Sandwich}
              onClick={() => {
                if (selectedCategory === Categories.Sandwich)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Sandwich);
              }}
            />
            <CategoryBadge
              title="Dessert"
              active={selectedCategory === Categories.Dessert}
              onClick={() => {
                if (selectedCategory === Categories.Dessert)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Dessert);
              }}
            />

            <CategoryBadge
              title="Beverage"
              active={selectedCategory === Categories.Beverage}
              onClick={() => {
                if (selectedCategory === Categories.Beverage)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Beverage);
              }}
            />
            <CategoryBadge
              title="Meat"
              active={selectedCategory === Categories.Meat}
              onClick={() => {
                if (selectedCategory === Categories.Meat)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Meat);
              }}
            />
            <CategoryBadge
              title="Breakfast"
              active={selectedCategory === Categories.Breakfast}
              onClick={() => {
                if (selectedCategory === Categories.Breakfast)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Breakfast);
              }}
            />
            <CategoryBadge
              title="Main"
              active={selectedCategory === Categories.Main}
              onClick={() => {
                if (selectedCategory === Categories.Main)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Main);
              }}
            />
            <CategoryBadge
              title="Misc."
              active={selectedCategory === Categories.Any}
              onClick={() => {
                if (selectedCategory === Categories.Any)
                  setSelectedCategory('all');
                else setSelectedCategory(Categories.Any);
              }}
            />
          </section>
          <IonList className="flex flex-col gap-y-4">
            {generateFoodTier('1')}
            {generateFoodTier('2')}
            {generateFoodTier('3')}
          </IonList>
        </>
      )}
      {activeIndex === 1 && (
        <div>
          {user ? (
            <>
              <IonList className="flex flex-col gap-y-4">
                {savedFoods &&
                  matchesSearch(savedFoods).map((food, index) => (
                    <FoodItem
                      handleSelectItem={handleSelectItem}
                      food={food}
                      key={index}
                    />
                  ))}
              </IonList>
              <div className="text-gray-400 flex flex-col items-center mt-4">
                <small>Not seeing what you expected?</small>
                <small>Hit the refresh button in the top right.</small>
              </div>
            </>
          ) : (
            <div className="px-4">
              <Button
                text="Sign in to add favorites"
                onClick={handleRedirectToProfile}
              />
            </div>
          )}
        </div>
      )}
      {/* An empty div to provide some padding to the bottom of the content */}
      <div className="w-full h-10" />
      <IonModal
        isOpen={showModal}
        initialBreakpoint={0.9}
        breakpoints={[0, 0.9, 1]}
        backdropBreakpoint={0}
        onDidDismiss={() => {
          handleCloseModal();
        }}
        backdropDismiss={true}
        handleBehavior="cycle"
      >
        <FoodDetail
          food={selectedMarker}
          savedFoods={savedFoods}
          handleDismiss={() => handleCloseModal()}
          handleShowLoginModal={() => setShowLoginModal(true)}
        />
      </IonModal>
      <IonModal
        isOpen={showLoginModal}
        ref={promptLoginModal}
        trigger="open-modal"
        presentingElement={presentingElement!}
      >
        <Profile
          onDismiss={() => setShowLoginModal(false)}
          dismissType="close"
        />
      </IonModal>
    </PageWrapper>
  );
};

export default FoodList;
