import { IonButton } from '@ionic/react';
import { ReactNode } from 'react';
import QRCode from 'react-qr-code';
import './MobilePreviewFrame.scss';
// import logo from '/assets/icon/icon-192x192.png';

interface Props {
  toggleFullscreen: () => void;
  children: ReactNode;
}

const MobilePreviewFrame = (props: Props) => {
  const appName = 'MN Fair Foodie Guide';
  const appDescription =
    'Follow a curated map of the best foods at the Minnesota State Fair!';
  const href: string = window.location.href;
  const webUrl: string | null = null;
  const logoUrl: string | null = '/assets/icon/icon-192x192.png';
  const shouldRenderSidebar = webUrl !== null || logoUrl !== null;

  const renderSidebar = () => {
    if (shouldRenderSidebar) {
      return (
        <div className="side-bar">
          {/* Render logo if available */}
          {logoUrl !== null ? (
            <div className="pb-6 justify-center">
              <img src={logoUrl} className="logo" alt="MN Fair Food Guide" />
            </div>
          ) : (
            ''
          )}

          {/* Render website link if available */}
          {webUrl !== null ? (
            <div className="flex justify-center">
              <IonButton href={webUrl} target="_blank" className="w-[170px]">
                Visit Website
              </IonButton>
            </div>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div className="preview-container bg-gray-100">
      {renderSidebar()}
      <div className="preview-content">
        {/* Mobile display */}
        <div className="flex justify-end col-span-3">
          <div className="iphone-x">
            <i className="speaker">Speaker</i>
            <b className="camera">Camera</b>
            <div className="absolute inset-0 mt-8 mb-4">{props.children}</div>
          </div>
        </div>

        {/* Details */}
        <div className="description col-span-3">
          <div
            className="
            h-[90px]
            w-[90px]
            rounded-[12px]
            shadow-md
            mb-5
            bg-white
            overflow-hidden
          "
          >
            <img src={logoUrl} className="object-contain h-[90px] w-[90px]" />
          </div>
          <h1 className="pb-4 text-6xl text-gray-800">{appName}</h1>
          <p className="pb-8 text-gray-600 max-w-[450px]">{appDescription}</p>
          <IonButton className="mb-8" onClick={() => props.toggleFullscreen()}>
            View Fullscreen
          </IonButton>
          <div
            className="
            rounded-[8px]
            shadow-lg
            overflow-hidden
            mt-6
            bg-white
            qr-container
          "
          >
            <QRCode value={href} size={160} />
            <div className="download-text">
              <h3 className="text-gray-800 text-xl">
                Scan the QR code to get the {appName} on your mobile device!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobilePreviewFrame;
